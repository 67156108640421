import { Exception } from '@app/_helpers/exception';
import { MessageBuilder } from '@app/_helpers/message-builder';
import { Buffer } from 'buffer';
import { algo, SHA256 as createHash, enc, lib } from 'crypto-js';
import { base64 as utilsbase64 } from './base64';
import { generateRandom } from './randomString';
function timingSafeEqual(a: Buffer, b: Buffer) {
  if (!Buffer.isBuffer(a)) {
    throw new TypeError('First argument must be a buffer');
  }
  if (!Buffer.isBuffer(b)) {
    throw new TypeError('Second argument must be a buffer');
  }
  if (a.length !== b.length) {
    return false;
  }
  var len = a.length;
  var out = 0;
  var i = -1;
  while (++i < len) {
    out |= a[i] ^ b[i];
  }
  return out === 0;
}
function safeEqual<P1 extends string, P2 extends string>(trustedValue: P1, userInput: P2) {
  return timingSafeEqual(Buffer.from(trustedValue), Buffer.from(userInput));
}
/**
 * A generic class for generating SHA-256 Hmac for verifying the value
 * integrity.
 */
export class Hmac {
  constructor(private readonly key: Buffer) {}

  /**
   * Generate the hmac
   */
  public generate(value: string) {
    return createHash(value, { key: this.key }).toString(enc.Base64url);
  }

  /**
   * Compare raw value against an existing hmac
   */
  public compare(value: string, existingHmac: string) {
    return safeEqual(this.generate(value), existingHmac);
  }
}

const exceptions = {
  E_MISSING_APP_KEY: { message: 'Invalid App Secret', status: 500, code: 'E_INVALID_APP_KEY' },
  E_INSECURE_APP_KEY: { message: 'Insecure App Secret', status: 500, code: 'E_INSECURE_APP_KEY' },
};

/**
 * Message verifier is similar to the encryption. However, the actual payload
 * is not encrypted and just base64 encoded. This is helpful when you are
 * not concerned about the confidentiality of the data, but just want to
 * make sure that is not tampered after encoding.
 */
export class MessageVerifier {
  /**
   * The key for signing and encrypting values. It is derived
   * from the user provided secret.
   */
  private readonly cryptoKey = Buffer.from(createHash(this.secret).toString(enc.Base64), 'base64');

  /**
   * Use `dot` as a separator for joining encrypted value, iv and the
   * hmac hash. The idea is borrowed from JWT's in which each part
   * of the payload is concatenated with a dot.
   */
  private readonly separator = '.';

  constructor(private readonly secret: string) {}

  /**
   * Signs a value with the secret key. The signed value is not encrypted, but just
   * signed for avoiding tampering to the original message.
   *
   * Any `JSON.stringify` valid value is accepted by this method.
   */
  public sign(value: any, expiresAt?: string | number, purpose?: string) {
    if (value === null || value === undefined) {
      throw new Exception('"MessageVerifier.sign" cannot sign null or undefined values');
    }

    const encoded = utilsbase64.urlEncode(new MessageBuilder().build(value, expiresAt, purpose));
    return `${encoded}${this.separator}${new Hmac(this.cryptoKey).generate(encoded)}`;
  }

  /**
   * Unsign a previously signed value with an optional purpose
   */
  public unsign<T = any>(value: string, purpose?: string): null | T {
    if (typeof value !== 'string') {
      throw new Exception('"MessageVerifier.unsign" expects a string value');
    }

    /**
     * Ensure value is in correct format
     */
    const [encoded, hash] = value.split(this.separator);
    if (!encoded || !hash) {
      return null;
    }

    /**
     * Ensure value can be decoded
     */
    const decoded = utilsbase64.urlDecode(encoded);
    if (!decoded) {
      return null;
    }

    const isValid = new Hmac(this.cryptoKey).compare(encoded, hash);
    return isValid ? new MessageBuilder().verify(decoded, purpose) : null;
  }
}

export class AppKeyException extends Exception {
  public static missingAppKey(): AppKeyException {
    const { message, ...opts } = exceptions.E_MISSING_APP_KEY;
    const error = new this(message, {
      ...opts,
    });
    return error;
  }

  public static insecureAppKey(): AppKeyException {
    const { message, ...opts } = exceptions.E_INSECURE_APP_KEY;
    const error = new this(message, {
      ...opts,
    });
    return error;
  }
}

interface EncryptionOptions {
  secret: string;
  algorithm?: string;
}
export class Encryption {
  private readonly algorithm = this.options.algorithm || 'aes-256-cbc';
  private readonly separator = '.';
  private readonly cryptoKey: lib.WordArray;
  private readonly base64 = utilsbase64;
  /**
   * Reference to the instance of message verifier for signing
   * and verifying values.
   */
  public verifier: MessageVerifier;
  constructor(private readonly options: EncryptionOptions) {
    this.validateSecret();
    this.cryptoKey = createHash(this.options.secret);
    this.verifier = new MessageVerifier(this.options.secret);
  }

  /**
   * Validates the app secret
   */
  private validateSecret() {
    if (typeof this.options.secret !== 'string') {
      throw AppKeyException.missingAppKey();
    }

    if (this.options.secret.length < 16) {
      throw AppKeyException.insecureAppKey();
    }
  }

  /**
   * Encrypt value with optional expiration and purpose
   */
  public encrypt(value: any, expiresAt?: string | number, purpose?: string) {
    /**
     * Using a random string as the iv for generating unpredictable values
     */
    const ivString = generateRandom(16);
    const iv = lib.WordArray.create(Buffer.from(ivString, 'utf8'));

    /**
     * Creating chiper
     */
    const cipher = algo.AES.createEncryptor(this.cryptoKey, { iv });
    /**
     * Encoding value to a string so that we can set it on the cipher
     */
    const encodedValue = new MessageBuilder().build(value, expiresAt, purpose);

    /**
     * Set final to the cipher instance and encrypt it
     */
    const encrypted = Buffer.concat(
      [cipher.process(lib.WordArray.create(Buffer.from(encodedValue, 'utf8'))), cipher.finalize()].map((d) => {
        d.clamp();
        return Buffer.from(d.toString(enc.Hex), 'hex');
      }),
    );

    /**
     * Concatenate `encrypted value` and `iv` by urlEncoding them. The concatenation is required
     * to generate the HMAC, so that HMAC checks for integrity of both the `encrypted value`
     * and the `iv`.
     */
    const result = `${this.base64.urlEncode(encrypted)}${this.separator}${iv.toString(enc.Base64url)}`;

    /**
     * Returns the result + hmac
     */
    return `${result}${this.separator}${new Hmac(Buffer.from(this.cryptoKey.toString(enc.Hex), 'hex')).generate(
      result,
    )}`;
  }

  /**
   * Decrypt value and verify it against a purpose
   */
  public decrypt<T = any>(value: string, purpose?: string): T | null {
    if (typeof value !== 'string') {
      throw new Exception('"Encryption.decrypt" expects a string value');
    }

    /**
     * Make sure the encrypted value is in correct format. ie
     * [encrypted value]--[iv]--[hash]
     */
    const [encryptedEncoded, ivEncoded, hash] = value.split(this.separator);
    if (!encryptedEncoded || !ivEncoded || !hash) {
      return null;
    }

    /**
     * Make sure we are able to urlDecode the encrypted value
     */
    const encrypted = this.base64.urlDecode(encryptedEncoded, 'base64');
    if (!encrypted) {
      return null;
    }

    /**
     * Make sure we are able to urlDecode the iv
     */
    const iv = this.base64.urlDecode(ivEncoded);
    if (!iv) {
      return null;
    }

    /**
     * Make sure the hash is correct, it means the first 2 parts of the
     * string are not tampered.
     */
    const isValidHmac = new Hmac(Buffer.from(this.cryptoKey.toString(enc.Hex), 'hex')).compare(
      `${encryptedEncoded}${this.separator}${ivEncoded}`,
      hash,
    );

    if (!isValidHmac) {
      return null;
    }

    /**
     * The Decipher can raise exceptions with malformed input, so we wrap it
     * to avoid leaking sensitive information
     */
    const ivWord = lib.WordArray.create(Buffer.from(iv, 'utf8'));
    try {
      const decipher = algo.AES.createDecryptor(this.cryptoKey, { iv: ivWord });
      const decrypted =
        decipher.process(lib.WordArray.create(Buffer.from(encrypted, 'base64'))).toString(enc.Utf8) +
        decipher.finalize().toString(enc.Utf8);
      const parsed: T = new MessageBuilder().verify(decrypted, purpose);

      return parsed;
    } catch (error) {
      return null;
    }
  }
}
